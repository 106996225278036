<template>
    <section>
        <div class="row mx-0 pt-3">
            <div class="col-8 pl-0">
                <tabla-general :data="pedidos" class="cr-pointer" alto="calc(100vh - 287px)" :mostrar-buscador="false" @filaFuncion="seleccionar_pedido">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-500 py-2">
                            Entregas realizadas por el delivery en los últimos 30 días
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column align="center" min-width="95" label="Pedido">
                            <template slot-scope="scope">
                                <div class="row mx-0">
                                    <div v-show="pedido_actual == scope.row.id" class="bg-general br-12" style="width: 8px; height: 39px" />
                                    <p class="col cr-pointer d-middle text-general f-600 text-center">
                                        {{ scope.row.id }}
                                    </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tendero" :label="$config.vendedor" min-width="180">
                            <template slot-scope="{ row }">
                                <el-tooltip placement="bottom" effect="light">
                                    <div slot="content">
                                        {{ row.direccion }}<br />{{ row.distancia }} Mts. Aprox.
                                    </div>
                                    <p class="" v-text="row.tendero" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="Entregado a" min-width="100">
                            <template slot-scope="{ row }">
                                <p> {{ row.cliente_entrega_directa ? 'Cliente' : $config.vendedor }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cant_productos" label="Productos" align="center" min-width="95" />
                        <el-table-column label="Valor" align="center" min-width="120">
                            <template slot-scope="{ row }">
                                <p>{{ convertMoney(row.valor_final, row.idm_moneda) }}</p>
                                <p />
                            </template>
                        </el-table-column>
                        <el-table-column label="Entrega" align="center" width="100">
                            <template slot-scope="{ row }">
                                <p>{{ row.entrega_fecha | helper-fecha("DD MMM") }}</p>
                                <p />
                                <p>{{ row.entrega_horario + ":00" }}</p>
                                <p />
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
            <div class="col bg-white br-t-8 px-0">
                <div class="row mx-0 border-b-gris pt-2" style="height:45px;">
                    <div
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab == 'productos' ? 'border-b-black text-general' : ''"
                    @click="tab = 'productos'"
                    >
                        Productos
                    </div>
                    <div
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab == 'detalle' ? 'border-b-black text-general' : ''"
                    @click="tab = 'detalle'"
                    >
                        Detalle
                    </div>
                </div>
                <div class="mx-0">
                    <productos v-show="tab === 'productos'" @update="listar" />
                    <detalle v-if="tab === 'detalle'" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Entregas from "~/services/entregas/pedidos-admin";
export default {
    components: {
        productos: () => import('./components/productos'),
        detalle: () => import('./components/detalle'),
    },
    data(){
        return {
            tab: "productos",
            buscar: "",

            pedido_actual: null,

            pedidos: [],
            fecha: "",
            aTiempo: false,
        };
    },
    computed: {
        id_delivery(){
            return Number(this.$route.params.id_delivery);
        },
    },
    mounted(){
        this.listar();
    },
    methods: {
        async listar(){
            try {
                const { data } = await Entregas.listarEntregasDelivery( this.id_delivery );
                this.pedidos = data.pedidos;
            } catch (e){
                this.error_catch(e);
            }
        },
        seleccionar_pedido(row){
            this.pedido_actual = row.id;
            this.$store.commit("pedidos/pedidos_admin/set_id_pedido", row.id);
        },
    },
};
</script>

<style>
</style>
