var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row mx-0 pt-3"},[_c('div',{staticClass:"col-8 pl-0"},[_c('tabla-general',{staticClass:"cr-pointer",attrs:{"data":_vm.pedidos,"alto":"calc(100vh - 287px)","mostrar-buscador":false},on:{"filaFuncion":_vm.seleccionar_pedido}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col-auto text-general f-500 py-2"},[_vm._v(" Entregas realizadas por el delivery en los últimos 30 días ")])]),_c('template',{slot:"adicionales-izquierda"},[_c('el-table-column',{attrs:{"align":"center","min-width":"95","label":"Pedido"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"row mx-0"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pedido_actual == scope.row.id),expression:"pedido_actual == scope.row.id"}],staticClass:"bg-general br-12",staticStyle:{"width":"8px","height":"39px"}}),_c('p',{staticClass:"col cr-pointer d-middle text-general f-600 text-center"},[_vm._v(" "+_vm._s(scope.row.id)+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"tendero","label":_vm.$config.vendedor,"min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"placement":"bottom","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(row.direccion)),_c('br'),_vm._v(_vm._s(row.distancia)+" Mts. Aprox. ")]),_c('p',{domProps:{"textContent":_vm._s(row.tendero)}})])]}}])}),_c('el-table-column',{attrs:{"label":"Entregado a","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(" "+_vm._s(row.cliente_entrega_directa ? 'Cliente' : _vm.$config.vendedor))])]}}])}),_c('el-table-column',{attrs:{"prop":"cant_productos","label":"Productos","align":"center","min-width":"95"}}),_c('el-table-column',{attrs:{"label":"Valor","align":"center","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(_vm.convertMoney(row.valor_final, row.idm_moneda)))]),_c('p')]}}])}),_c('el-table-column',{attrs:{"label":"Entrega","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(_vm._f("helper-fecha")(row.entrega_fecha,"DD MMM")))]),_c('p'),_c('p',[_vm._v(_vm._s(row.entrega_horario + ":00"))]),_c('p')]}}])})],1)],2)],1),_c('div',{staticClass:"col bg-white br-t-8 px-0"},[_c('div',{staticClass:"row mx-0 border-b-gris pt-2",staticStyle:{"height":"45px"}},[_c('div',{staticClass:"col pt-0 d-middle-center cr-pointer",class:_vm.tab == 'productos' ? 'border-b-black text-general' : '',on:{"click":function($event){_vm.tab = 'productos'}}},[_vm._v(" Productos ")]),_c('div',{staticClass:"col pt-0 d-middle-center cr-pointer",class:_vm.tab == 'detalle' ? 'border-b-black text-general' : '',on:{"click":function($event){_vm.tab = 'detalle'}}},[_vm._v(" Detalle ")])]),_c('div',{staticClass:"mx-0"},[_c('productos',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 'productos'),expression:"tab === 'productos'"}],on:{"update":_vm.listar}}),(_vm.tab === 'detalle')?_c('detalle'):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }